import { mapState } from 'vuex';
import CREATE_CAMPAIGN from '@/graphql/CreateCampaign.gql';
import { get as _get } from 'lodash-es';
import { getRedirectDomain } from '@/utils/dcHelpers';
import { createDCUrl } from '@/utils/pncURLBuilder';

export default {
  computed: {
    ...mapState(['account']),
  },
  methods: {
    /**
     *
     * @param {Object} campaignParams
     * @param campaignParams.selectedDomain
     * @param campaignParams.type
     * @param {Object} campaignParams.urlModUrl
     * @param {string} urlModUrl.gotUrl
     * @param {string} urlModUrl.path
     * @return {Promise<string>}
     */
    async saveCampaignRaw({ selectedDomain, type, urlModUrl, isSAB }) {
      if (!selectedDomain) return null;

      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            input: {
              name: '',
              domainId: selectedDomain._id,
              templateId: null,
              dynamicContent: true,
              addControlVariant: type === 'abTest' && !isSAB,
            },
          },
        });

        const campaignId = _get(response, 'data.createCampaign.id');
        const variantId = _get(response, 'data.createCampaign.variants.0._id');

        if (!campaignId || !variantId) {
          throw new Error('Failed to create campaign');
        }

        const [variant] = _get(response, 'data.createCampaign.variants');
        const campaignDomain = _get(response, 'data.createCampaign.domain');
        const redirectUrl = this.getDynamicContentUrl(
          getRedirectDomain(urlModUrl.gotUrl, campaignDomain),
          campaignId,
          this.$i18n.locale,
          variant._id,
          urlModUrl.path ?? '',
          isSAB,
        );

        return redirectUrl;
      } catch (e) {
        console.log(e);
        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
      }
    },
    getDynamicContentUrl(campaignDomain, campaignId, locale, variantId, pathName, isSAB) {
      return createDCUrl({
        domain: campaignDomain,
        campaignId,
        locale,
        variantId,
        path: pathName,
        returnUrl: `/${this.account.databaseId}/campaign/${campaignId}/settings?activeBox=condition`,
        backUrl: `/${this.account.databaseId}/campaign/${campaignId}`,
        isNew: true,
        isSuperAdmin: this.isSuperAdmin,
        featureMode: isSAB ? 'smart-ab-test' : null,
      });
    },
  },
};
