<template lang="pug">
.new-campaign-url-input(:class="classObject")
  Layout(v-if="isURLInputPage" @back="navigateBack")
    UrlInput(:initUrl="url.gotUrl" :loading="$apollo.loading || isLoading" @next="onNext")
  router-view(v-else)
</template>

<script>
  import { mapGetters } from 'vuex';
  import { lsStore } from '@/helpers/localStorage';
  import urlModsMixin from '@/mixins/urlMods';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import GET_SITE_INFO from '@/graphql/GetSiteInfo.gql';
  import GET_DOMAIN_CONTEXTS from '@/graphql/GetDomainContexts.gql';
  import domainContextMixin from '@/mixins/domainContext';
  import dcCampaignMixin from '@/mixins/dcCampaignCreate';
  import { purifyDomain } from '@/util';

  const isSABHome = (routeName) => routeName === 'new_campaign_flow_sab';
  const isDCHome = (routeName) => routeName === 'new_campaign_flow_dc';

  export default {
    components: {
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
      UrlInput: () => import('@/components/NewCampaignFlow/contents/URLInput.vue'),
    },
    mixins: [urlModsMixin, domainContextMixin, dcCampaignMixin],
    data: () => ({ siteInfo: null, hasError: null, isLoading: false }),
    computed: {
      ...mapGetters(['domains']),
      showBack() {
        return this.$route.meta?.showBack ?? true;
      },
      isSABHome() {
        return isSABHome(this.$route.name);
      },
      isURLInputPage() {
        return this.isSABHome || isDCHome(this.$route.name);
      },
      classObject() {
        return {
          'new-campaign-sab-flow': this.isSABHome,
          'new-campaign-dc-flow': !this.isSABHome,
        };
      },
      type() {
        return this.$route.query?.type ?? 'abTest';
      },
      nextRouteName() {
        return this.isSABHome
          ? 'new_campaign_flow_sab_branching'
          : 'new_campaign_flow_dc_verification';
      },
    },
    apollo: {
      siteInfo: {
        query: GET_SITE_INFO,
        variables() {
          return {
            url: this.url.domain,
          };
        },
        update(data) {
          return data.getSiteInfo;
        },
        result() {
          const lsSiteInfoKey = `siteinfo-${this.url.domain}`;
          lsStore(lsSiteInfoKey, this.siteInfo);
        },
        skip: true,
      },
      domainContext: {
        query: GET_DOMAIN_CONTEXTS,
        watchLoading(loading) {
          if (loading) this.isLoading = true;
        },
        error(error) {
          console.log('error', error);
          this.hasError = true;
          this.isLoading = false;
        },
        async result({ data: { domainContexts } }) {
          const { _id: domainId } = this.savedDomain;
          const hasDomainContext =
            domainContexts.length &&
            domainContexts.find((context) => context.domainId === domainId);

          if (!hasDomainContext) {
            this.isLoading = true;
            await this.generateAndSetContext(domainId);
          }

          this.isLoading = false;
        },
        manual: true,
        skip: true,
      },
    },
    created() {
      this.setCampaignDomain(this.$route.query.url);
    },
    mounted() {
      if (this.isURLInputPage) {
        this.setQueryStringParameter('url');
      }
    },
    methods: {
      async addDomain() {
        await this.$apollo.mutate({
          mutation: ADD_DOMAIN,
          variables: {
            domain: purifyDomain(this.url.domain),
          },
        });
        await this.$store.dispatch('fetchDomains');
      },
      navigateBack() {
        this.$router.go(-1);
      },
      async generateAndSetContext(domainId) {
        const {
          data: { generateDomainContext },
        } = await this.generateDomainContext(domainId);

        if (generateDomainContext.success) {
          await this.setDomainContext(
            domainId,
            generateDomainContext.context,
            generateDomainContext.siteContent,
          );
        }
      },
      async onNext(url) {
        this.setCampaignDomain(url);
        if (!this.savedDomain) await this.addDomain();
        if (this.isSABHome) await this.checkRequirements();
        if (!this.hasError) {
          return this.goNextPage(url);
        }
      },
      async goNextPage(url) {
        this.setQueryStringParameter('url', url);
        this.$router.push({
          name: this.nextRouteName,
          query: { ...this.$route.query, url },
          params: { urlParams: this.url },
        });
      },
      async checkRequirements() {
        this.setSkipOfQueries(false);
        await Promise.all([
          this.$apollo.queries.siteInfo.refetch(),
          this.$apollo.queries.domainContext.refetch(),
        ]);
        this.setSkipOfQueries();
      },
      setSkipOfQueries(skip = true) {
        this.$apollo.queries.siteInfo.skip = skip;
        this.$apollo.queries.domainContext.skip = skip;
      },
    },
  };
</script>
